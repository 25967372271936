<template>
  <div>
    <DriverHeader />
    <section class="join-vite container pt-5">
      <h3 class="text-center mt-3">
        It is easy to join <span class="color-primary">vite</span>.
      </h3>
      <div class="row mt-3">
        <div class="col-12 col-sm-4">
          <div class="justify-content-center">
            <img
              src="../assets/imgs/driver/web.png"
              alt="image"
              class="join-vite-imgs d-block m-auto"
            />
            <h4 class="text-center mt-3">Signup</h4>
            <p class="text-center">
              Fill personal profile and upload Driving License, ID and Insurance
            </p>
          </div>
        </div>
        <div class="col-12 col-sm-4">
          <div class="justify-content-center">
            <img
              src="../assets/imgs/driver/kyc.png"
              alt="image"
              class="join-vite-imgs d-block m-auto"
            />
            <h4 class="text-center mt-3">Get Approved</h4>
            <p class="text-center">
              We review and activate your account, and then offer training
            </p>
          </div>
        </div>
        <div class="col-12 col-sm-4">
          <div class="justify-content-center">
            <img
              src="../assets/imgs/driver/transport.png"
              alt="image"
              class="join-vite-imgs d-block m-auto"
            />
            <h4 class="text-center mt-3">Pick Up Riders</h4>
            <p class="text-center">
              Start accepting rides and earn money driving with Vite
            </p>
          </div>
        </div>
      </div>
    </section>
    <section class="why-vite bg-light-grey py-3 mt-5">
      <h3 class="text-center mt-3">
        Why drive with <span class="color-primary">vite</span>.
      </h3>
      <div class="container">
        <div class="row mt-3">
          <div class="col-12 col-sm-4">
            <div class="justify-content-center">
              <img
                src="../assets/imgs/driver/hands.png"
                alt="image"
                class="join-vite-imgs d-block m-auto"
              />
              <h4 class="text-center mt-3">Freedom</h4>
              <p class="text-center">
                As long as you are willing to work we will get you trips and
                won't get in the way.
              </p>
            </div>
          </div>
          <div class="col-12 col-sm-4">
            <div class="justify-content-center">
              <img
                src="../assets/imgs/driver/money.png"
                alt="image"
                class="join-vite-imgs d-block m-auto"
              />
              <h4 class="text-center mt-3">More Money</h4>
              <p class="text-center">
                It's your time, drive <br />it your way and earn when you want
              </p>
            </div>
          </div>
          <div class="col-12 col-sm-4">
            <div class="justify-content-center">
              <img
                src="../assets/imgs/driver/discussion.png"
                alt="image"
                class="join-vite-imgs d-block m-auto"
              />
              <h4 class="text-center mt-3">Make A Difference</h4>
              <p class="text-center">
                You are not just a driver. <br />
                Meet new friends and create memories
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="requirements">
      <div class="requirements-bg-color">
        <div class="container pb-3">
          <div class="row">
            <div class="col-12 col-lg-6 mt-5">
              <h3 class="color-white requirement-text">
                Our <br />
                Requirements to drive with Vite
              </h3>
            </div>
            <div class="col-12 col-lg-6 mt-5">
              <div class="row">
                <div class="col-lg-12 col-12">
                  <div class="requirement-right-container-div first mt-4">
                    <div class="row">
                      <div class="col-lg-3 col-12">
                        <img
                          src="../assets/imgs/driver/age.png"
                          alt="image"
                          class="d-block m-auto pt-lg-5 pt-2"
                        />
                      </div>
                      <div
                        class="col-lg-9 col-12 pt-lg-5 pt-2 text-center text-lg-left"
                      >
                        <h3>Age</h3>
                        <p>You must be 21 years or older</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-12 col-6">
                  <div class="requirement-right-container-div mt-4">
                    <div class="row">
                      <div class="col-lg-3 col-12">
                        <img
                          src="../assets/imgs/driver/driving-license.png"
                          alt="image"
                          class="d-block m-auto pt-lg-5 pt-2"
                        />
                      </div>
                      <div
                        class="col-lg-9 col-12 pt-lg-5 pt-2 text-center text-lg-left"
                      >
                        <h3 class="px-2">Licence</h3>
                        <p class="px-2">
                          You must be a licenced taxi driver with at least a
                          year experience
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-12 col-6">
                  <div class="requirement-right-container-div mt-4">
                    <div class="row">
                      <div class="col-lg-3 col-12">
                        <img
                          src="../assets/imgs/driver/taxi.png"
                          alt="image"
                          class="d-block m-auto pt-lg-5 pt-2"
                        />
                      </div>
                      <div
                        class="col-lg-9 col-12 pt-lg-5 pt-2 text-center text-lg-left"
                      >
                        <h3 class="px-2">Taxi</h3>
                        <p class="px-2">
                          Your taxi must not be older than 10 years
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="faq container mt-5">
      <div class="row justify-content-center">
        <div class="col-12 col-md-10">
          <h1 class="text-center mb-5">Frequently Asked Question</h1>
          <el-collapse v-model="activeName" accordion>
            <el-collapse-item
              title="Do you work with private drivers?"
              name="1"
            >
              <div>
                In Mauritius, we offer our technology to help only licensed taxi
                drivers connect with passengers quickly. However, in other
                cities like Luanda, we welcome private drivers.
              </div>
            </el-collapse-item>
            <el-collapse-item title="How do I get Paid?" name="2">
              <div>
                Drivers will collect the money for cash trips at the end of the
                ride. Where rider prefers juice or my.t money they can send it
                Vite and drivers will get their money instantly.
              </div>
            </el-collapse-item>
            <el-collapse-item title="How much Do I pay Vite?" name="3">
              <div>
                Drivers only pay 10% until end of the year. Commission will be
                15% from January - 2% will be used to offer fuel and maintenance
                bonus for drivers district by district.
              </div>
            </el-collapse-item>
            <el-collapse-item title="How to I get Started?" name="4">
              <div>
                It takes less than 5 minutes to signup! Use the form above to
                enter your initial details and register. Note: make sure to
                verify your number.
              </div>
            </el-collapse-item>
            <el-collapse-item title="Do I get insurance coverage?" name="5">
              <div>
                Most of the taxi drivers who use Vite are licensed and have
                insurance already. But if you do not have one yet or yours is
                expired, we will work with you to renew or get a new package
              </div>
            </el-collapse-item>
            <el-collapse-item title="I don't have a smartphone." name="6">
              <div>
                We will work with any taxi driver who's willing to use Vite app
                to get a budget smartphone to accept trips.
              </div>
            </el-collapse-item>
          </el-collapse>
          <h1 class="text-center mt-5">Frequently Asked Question</h1>
          <p class="text-center faq-bottom-text">
            If you cannot find answer to your question in our FAQ, you can
            always contact us. We will answer to you shortly!
          </p>
        </div>
      </div>
    </section>
    <section class="footer mt-5 pt-5">
      <div class="container mt-5">
        <div class="row">
          <div class="col-12 col-md-4">
            <h3 class="mb-md-4">About Vite</h3>
            <p>
              The Vite Team. builds innovative software solutions that simplify
              how people find and book local taxis and access delivery services.
            </p>
            <a
              class="color-white hover-white d-block"
              href="mailto:vitepartner@viteapp.co"
            >
              vitepartner@viteapp.co
            </a>
            <div class="row mt-5">
              <p class="mt-md-0 mt-4 col-6 col-md-12">
                <a href="/"
                  ><img
                    class="footer-google-img"
                    alt="image"
                    src="../assets/imgs/google-app.png"
                /></a>
                <a class="btn btn-primary ml-5" href="/downloads">download</a>
              </p>
              <p class="mt-md-0 mt-4 col-6 col-md-12">
                <a href="/"
                  ><img
                    class="footer-verified-img"
                    alt="image"
                    src="../assets/imgs/verified.png"
                /></a>
              </p>
            </div>
          </div>
          <div class="col-6 col-md-2 offset-md-1 mt-md-0 mt-5">
            <h3 class="mb-md-4">Links</h3>
            <p>
              <a
                href="https://driver.viteapp.co/"
                class="color-white hover-white d-block"
                >Become Driver</a
              >
              
              <a
                href="https://www.ohfood.mu/"
                class="color-white hover-white mt-2 d-block"
                target="_blank"
              >
                DELIVERY
              </a>
              <a
                class="clickable color-white hover-white mt-2 d-block"
                @click="dialogVisible = true"
              >
                Privacy Policy
              </a>
            </p>
          </div>
          <div class="col-6 col-md-2 mt-md-0 mt-5">
            <h3 class="mb-md-4">Social Links</h3>
            <p>
              <a
                href="https://www.facebook.com/viteapp.co/"
                class="color-white hover-white d-block"
                target="_black"
                >Facebook</a
              >
              <a
                href="https://wa.me/23059150708"
                class="color-white hover-white mt-2 d-block"
                target="_black"
                >Whatsapp
              </a>
              <a
                href="https://www.instagram.com/viteapp.co/"
                class="color-white hover-white mt-2 d-block"
                target="_black"
              >
                Instagram
              </a>
              <a
                href="https://twitter.com/vite_app"
                class="color-white hover-white mt-2 d-block"
                target="_black"
                >Twitter
              </a>
              <a
                href="https://www.youtube.com/channel/UC0-P3Sgj17PFOyMdXIjP9XQ?view_as=subscriber"
                class="color-white hover-white mt-2 d-block"
                target="_black"
                >Youtube
              </a>
            </p>
          </div>
          <div class="col-12 col-md-3 mt-md-0 mt-5">
            <h3 class="mb-md-4">Contact us</h3>
            <img
              src="../assets/imgs/driver/map.png"
              class="d-block"
              alt="image"
            />
            <p class="mt-3">
              soreze pailles (5,980.32 km) 112255 Port Louis, Mauritius
            </p>
            <p>
              Phone +230 5915 0708
            </p>
          </div>
        </div>
      </div>
      <div class="row justify-content-center p-4">
        <p>All rights reserved. © Viteapp, 2021</p>
      </div>
    </section>
  </div>
</template>

<script>
const DriverHeader = () => import("./DriverHeader.vue");

export default {
  name: "driver",
  components: {
    DriverHeader
  },
  data() {
    return {
      activeName: "1"
    };
  }
};
</script>

<style scoped>
.join-vite-imgs {
  height: 74px;
  width: 74px;
}
.requirements {
  width: 100%;
  min-height: 695px;
  background-image: url("../assets/imgs/driver/drive_bg2.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: center center;
}
.requirements-bg-color {
  width: 100%;
  min-height: 695px;
  background-image: url("../assets/imgs/driver/dirver-bg-transparent-2.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: center center;
}
.requirement-right-container-div {
  border: 3px solid #ffffff;
  width: 100%;
  color: #ffffff;
  border-radius: 25px;
  min-height: 168px;
}
.requirement-right-container-div.first {
  background-color: #fff;
  color: #222222;
}
.faq-bottom-text {
  font-size: 22px;
  color: #939393;
}
.footer {
  background-color: #222222;
  width: 100%;
  min-height: 448px;
}

.footer-google-img {
  width: 125px;
}

.footer {
  background-color: #222;
  color: #ffffff;
}
h3 span {
  font-size: 1.75rem;
}
@media screen and (min-width: 960px) {
  .requirement-text {
    padding-top: 22rem;
  }
}
</style>
